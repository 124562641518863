<template>
  <div class="blog-page blog-page-posts-index">
    <div class="container">
      <breadcrumbs
        :with-homepage="true"
        :routes="[]"
        :active-route="'Porady'"
      />
    </div>
    <div class="blog-page--hero">
      <h1 class="blog-page--page-title">
        {{ $t('Porady') }}
      </h1>
    </div>
    <div v-if="loading">
      <div class="container">
        <div class="blog-page--preview-large blog-page--preview-large-hero-merge bg-cl-secondary brdr-radius-20" />
      </div>
      <div class="blog-page--entries">
        <div class="container">
          <div class="row row--entries">
            <div class="col col-xs-12 col-md-6 col-lg-4">
              <div class="blog-page--preview-small">
                <p class="blog-page--preview-small-bg bg-cl-secondary" />
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date h-16 bg-cl-secondary w-50 brdr-radius-20" />
                  <h3 class="blog-page--preview-title h-25 mt-5 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt h-148 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt w-40 h-16 bg-cl-secondary mt-40 brdr-radius-20" />
                </div>
              </div>
            </div>
            <div class="col col-xs-12 col-md-6 col-lg-4">
              <div class="blog-page--preview-small">
                <p class="blog-page--preview-small-bg bg-cl-secondary" />
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date h-16 bg-cl-secondary w-50 brdr-radius-20" />
                  <h3 class="blog-page--preview-title h-25 mt-5 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt h-148 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt w-40 h-16 bg-cl-secondary mt-40 brdr-radius-20" />
                </div>
              </div>
            </div>
            <div class="col col-xs-12 col-md-6 col-lg-4">
              <div class="blog-page--preview-small">
                <p class="blog-page--preview-small-bg bg-cl-secondary" />
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date h-16 bg-cl-secondary w-50 brdr-radius-20" />
                  <h3 class="blog-page--preview-title h-25 mt-5 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt h-148 bg-cl-secondary brdr-radius-20" />
                  <p class="blog-page--preview-excerpt w-40 mt-40 h-16 bg-cl-secondary mt40 brdr-radius-20" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="posts && posts.length > 0">
      <div class="container">
        <div class="blog-page--preview-large blog-page--preview-large-hero-merge" :style="getLargePostImage(featuredPost)">
          <div class="blog-page--preview-large-inner">
            <h4 class="blog-page--preview-date">
              {{ featuredPost.date | prettyDate }}
            </h4>
            <h2 class="blog-page--preview-title">
              <router-link :to="localizedRoute(`/porady/${featuredPost.slug}`)" v-html="featuredPost.title" />
            </h2>
            <p class="blog-page--preview-excerpt" v-html="sanitizeHtml(featuredPost.excerpt, { allowedTags: [], allowedAttributes: {}, textFilter})" />
            <router-link :to="localizedRoute(`/porady/${featuredPost.slug}`)" class="blog-page--preview-link">
              {{ $t('Learn More') }}&nbsp;<span class="slide-right">&rtrif;</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="blog-page--entries">
        <div class="container">
          <div class="row row--entries">
            <div class="col col-md-6 col-lg-4" v-for="(post) in postList" :key="post.id">
              <div class="blog-page--preview-small">
                <router-link :to="localizedRoute(`/porady/${post.slug}`)" class="blog-page--preview-small-bg" :style="getMediumPostImage(post)">
                  &nbsp;
                </router-link>
                <div class="blog-page--preview-small-inner">
                  <h4 class="blog-page--preview-date">
                    {{ post.date | prettyDate }}
                  </h4>
                  <h3 class="blog-page--preview-title">
                    <router-link :to="localizedRoute(`/porady/${post.slug}`)" v-html="post.title" />
                  </h3>
                  <p class="blog-page--preview-excerpt no-break" v-html="sanitizeHtml(post.excerpt, { allowedTags: [], allowedAttributes: {}, textFilter})" />
                  <router-link :to="localizedRoute(`/porady/${post.slug}`)" class="blog-page--preview-link">
                    {{ $t('Learn More') }}&nbsp;<span class="slide-right">&rtrif;</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>{{ $t('There are no posts.') }}</h3>
    </div>
  </div>
</template>

<script>
import Posts from 'src/modules/vsf-wordpress/components/posts/Index.js'
import onBottomScroll from '@vue-storefront/core/mixins/onBottomScroll'

export default {
  mixins: [Posts, onBottomScroll],
  name: 'PostsIndex',
  data () {
    return {
      loadingPosts: false
    }
  },
  methods: {
    textFilter (text, tagName) {
      if (text === 'Continued' && tagName === 'a') {
        return text.replace('Continued', '')
      } else {
        return text
      }
    },
    async onBottomScroll () {
      if (this.loadingPosts) return
      if (this.currentPageInfo && !this.currentPageInfo.hasNextPage) return
      this.loadingPosts = true
      try {
        let cursor = this.currentEdges[this.currentEdges.length - 1]
        await this.$store.dispatch('wordpress/loadPagePosts', { first: 12, cursor: cursor.cursor })
      } catch (e) {
        console.error('Problem with fetching more posts')
      } finally {
        this.loadingPosts = false
      }
    }
  },
  metaInfo () {
    let titleTemplate = '%s'
    return {
      titleTemplate
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/modules/vsf-wordpress/components/blog/scss/blog';

.blog-page-posts-index {
  background: #f9f9f9;
  .blog-page--hero {
    padding-top: 60px;
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-top: 0px;
    }
  }
  .blog-page--page-title {
    display: block;
    text-align: center;
    color: $nearly-black;
    margin: 0 auto 30px;
    max-width: 650px;
  }
}
  .no-break {
    word-break: break-word;
  }
  .h-44 {
    height: 44px;
  }
  .h-25 {
    height: 25px;
  }
  .h-16 {
    height: 16px;
  }
  .h-148 {
    height: 148px;
  }
  .w-40 {
    width: 40%;
  }
  .mt-40 {
    margin-top: 40px !important;
  }
  .mt-5 {
    margin-top: 5px !important;
  }
</style>

import { mapGetters } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'

export default {
  components: {
    Breadcrumbs
  },
  data () {
    return {
      loading: false
    }
  },
  serverPrefetch () {
    return this.fetchPosts()
  },
  mounted () {
    this.fetchPosts()
  },
  computed: {
    ...mapGetters({
      posts: 'wordpress/posts',
      currentPageInfo: 'wordpress/currentPageInfo',
      currentEdges: 'wordpress/currentEdges'
    }),
    postList () {
      if (this.posts && this.posts.length > 0) {
        return this.posts.slice(1)
      } else {
        return []
      }
    },
    featuredPost () {
      if (this.posts && this.posts.length > 0) {
        return this.posts.slice(0, 1)[0]
      } else {
        return null
      }
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.length > 0 ? text.substring(0, length) + suffix : ''
    },

    prettyDate: function (date) {
      let newDate = new Date(date)
      let dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return newDate.toLocaleDateString('pl-PL', dateFormat)
    }
  },
  methods: {
    fetchPosts () {
      this.loading = true
      return this.$store.dispatch('wordpress/loadPosts', { first: 12 }).then(() => { this.loading = false }).catch(() => { this.loading = false })
    },
    getLargePostImage (post) {
      if (post.featuredImage != null && post.featuredImage.node.large != null) {
        return {
          'backgroundImage': `url(${post.featuredImage.node.large})`
        }
      } else {
        return {
          'backgroundColor': `#ccc`
        }
      }
    },
    getMediumPostImage (post) {
      if (post.featuredImage != null && post.featuredImage.node.medium != null) {
        return {
          'backgroundImage': `url(${post.featuredImage.node.medium})`
        }
      } else {
        return {
          'backgroundColor': `#ccc`
        }
      }
    },
    sanitizeHtml: sanitizeHtml
  }
}

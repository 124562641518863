<template>
  <posts-index />
</template>

<script>
import PostsIndex from 'theme/components/core/blocks/Wordpress/posts/Index'
import { isServer } from '@vue-storefront/core/helpers'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { Wordpress } from 'src/modules/vsf-wordpress'

export default {
  name: 'PostsPageIndex',
  components: { PostsIndex },
  beforeCreate () {
    registerModule(Wordpress)
  },
  // serverPrefetch () {
  //   registerModule(Wordpress)
  // },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  }
}
</script>

<style scoped>
  /* Always export scoped styles to not break the app */
</style>
